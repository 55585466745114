<template>
  <div class="content">
    <div class="topHeader">
      <div class="userImg">
        <img class="img" :src="dataList.headPortrait" />
      </div>
      <div class="userCard">
        <div class="userCard_left">
          <div class="line_one">
            <div class="black_one">{{ dataList.wechatNickname }}</div>
            <div class="icon_man">
              <img src="../../../../../static/images/jr-icon-man.png" v-if="dataList.gender == 2" />
              <img
                src="../../../../../static/images/jr-icon-female.png"
                v-if="dataList.gender == 1"
              />
            </div>
          </div>
          <div class="line_two">
            <div class="icon_man">
              <img src="../../../../../static/images/jifeng.png" />
            </div>
            <span style="font-size: 14px">积分</span>
            <span class="lien_two_color">{{ dataList.integral }}</span>
          </div>
          <div class="line_three">
            <div @click="goDetailList(2)">他关注的({{this.showList.myFocusCount}})</div>
            <div @click="goDetailList(3)" class="right_one">关注他的({{this.showList.payAttentionToMyCount}}) </div>
            <div @click="goDetailList(1)">参与活动({{this.showList.myActivitiesCount}}) </div>
            <div @click="goDetailList(4)" class="right_one">参与项目({{this.showList.myProjectCount}}) </div>
          </div>
        </div>
        <div class="userCard_right">
          <div v-if="this.dataList.whetherMember == 1" class="VIPImg">
            <img src="../../../../../static/images/VIP.png" class="userCard_img" />
          </div>
          <div class="deadline" v-if="this.dataList.whetherMember == 1">
            {{ dataList.deadline }}到期
          </div>
          <img
            src="../../../../../static/images/user.png"
            class="userCard_img"
            v-if="this.dataList.whetherMember == 0"
          />
        </div>
      </div>
    </div>
    <div class="tip">
      <div class="tipOne"></div>
      基本信息
    </div>
    <div class="info">
      <el-descriptions title="用户信息" :column="2">
        <el-descriptions-item label="用户名ID">{{ dataList.id }}</el-descriptions-item>
        <el-descriptions-item label="所在城市">{{ dataList.ipCity }}</el-descriptions-item>
        <el-descriptions-item label="真实姓名">{{ dataList.userName }}</el-descriptions-item>
        <el-descriptions-item label="当前机构">{{ dataList.institution }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ dataList.mobile }}</el-descriptions-item>
        <el-descriptions-item label="当前职位">{{ dataList.position }}</el-descriptions-item>
        <el-descriptions-item label="微信号">{{ dataList.weChatId }}</el-descriptions-item>
        <el-descriptions-item label="登录次数">{{ dataList.loginCount }}</el-descriptions-item>
        <el-descriptions-item label="最后登陆时间">{{
          dataList.lastLoginTime
        }}</el-descriptions-item>
        <el-descriptions-item label="最后登录IP/城市"
          >{{ dataList.ip }}&nbsp;|&nbsp;{{ dataList.ipCity }}</el-descriptions-item
        >
        <el-descriptions-item label="入会时间">{{ dataList.beginTime }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ dataList.applicationTime }}</el-descriptions-item>
        <el-descriptions-item label="标签"></el-descriptions-item>
      </el-descriptions>
      <div class="PI">
        <div class="text_area">{{ dataList.personalIntro }}</div>
      </div>
    </div>
    <div class="tip">
      <div class="tipOne"></div>
      名片
    </div>
    <div class="person_Card" v-if="dataList.businessCard">
      <!-- <img :src="dataList.businessCard[0]" />
      <img :src="dataList.businessCard[1]" /> -->
      <el-table-column label="员工照片" align="center">
   <template >
     <el-image
       style="width: 290px;height: 200px;"
       :src="dataList.businessCard[0]"
       :preview-src-list="srcList"
       @click="vbs(dataList.businessCard[0])"
     >
     </el-image>
      <el-image
      style="width: 290px;height: 200px;"
       :src="dataList.businessCard[1]"
       :preview-src-list="srcList"
       @click="vbs(dataList.businessCard[1])"
     >
     </el-image>
   </template>
</el-table-column>

    </div>
    <div class="person_Card" v-else>
      <div>暂无名片</div>
    </div>
  </div>
</template>
<script>
import { getUserDetail, getAllCount } from '@/api/userManagement.js'
export default {
  components: {},
  data() {
    return {
      dataList:'',
      showList:{},
      srcList:[]
    }
  },
  mounted() {
    this.getUserDetail()
    this.getAllCount(this.$route.query.id)
  },
  methods: {
    async getAllCount(id) {
      const { data: res } = await getAllCount({ id: id })
      this.showList.myActivitiesCount = res.data.myActivitiesCount
      this.showList.myFocusCount = res.data.myFocusCount
      this.showList.myProjectCount = res.data.myProjectCount
      this.showList.payAttentionToMyCount = res.data.payAttentionToMyCount
    },
    vbs(val) {
      this.srcList = []
      this.srcList.push(val)
    },
    async getUserDetail() {
      const { data: res } = await getUserDetail({ id: this.$route.query.id })
      this.dataList = res.data
      if(this.dataList.deadline) this.dataList.deadline = this.dataList.deadline.split(' ')[0]
      // console.log(this.dataList, 'this.dataList')
    },
    goDetailList(typeNum) {
      this.$router.push({
        path: `/userManagement/userDetail/userDetailList?typeNum=${typeNum}&id=${this.dataList.id}&whetherMember=${this.dataList.whetherMember}`
      })
    }
  }
}
</script> 
<style scoped lang="less">
.content {
  background: #ffffff;
  margin: 16px 0px 0px 25px;
  overflow: hidden;
  height: auto !important;
  .topHeader {
    margin: 25px 0px 0px 27px;
    display: flex;
    .userImg {
      width: 160px;
      height: 160px;
      border-radius: 4px;
      .img {
        width: 100%;
        height: 100%;
      }
    }
    .userCard {
      margin-left: 25px;
      width: 480px;
      height: 160px;
      background: linear-gradient(91.34deg, #d6d6fe 1.98%, #e3d6ff 57.14%, #abc3fd 98.77%);
      border-radius: 12px;
      .userCard_left {
        float: left;
        margin: 15px 0px 15px 30px;
        .line_one {
          display: flex;
          .black_one {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            margin-right: 15px;
          }
          .icon_man {
            width: 16px;
            height: 14px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .line_two {
          display: flex;
          margin-top: 6px;
          span {
            margin-top: 3px;
          }
          .icon_man {
            width: 10px;
            height: 10px;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .lien_two_color {
            color: #7c71e1;
            font-size: 14px;
            line-height: 20px;
            margin-left: 10px;
          }
        }
        .line_three {
          display: flex;
          flex-wrap: wrap;
          width: 240px;
          margin-top: 10px;
          .right_one {
            margin-left: 90px;
          }
          div {
            margin-top: 15px;
            color: #7c71e1;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
      .userCard_right {
        float: right;
        width: 142.8px;
        height: 119px;
        margin: 21px 21px 21px 0px;
        .VIPImg {
          width: 125px;
          height: 105px;
        }
        .userCard_img {
          width: 100%;
          height: 100%;
        }
        .deadline {
          width: 125px;
          margin-top: 10px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #9388f9;
        }
      }
    }
  }
  .tip {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    display: flex;
    margin: 20px 0px 20px 25px;
    .tipOne {
      margin: 3px 6px 0px 0px;
      background: #7c71e1;
      border-radius: 11px;
      width: 4px;
      height: 18px;
    }
  }
  .info {
    margin: 20px 0px 0px 35px;
    width: 60%;
    .PI {
      background: #fbf9f9;
      width: 690px;
      height: 143px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 20px;
      color: #151515;
      overflow: hidden;
      .text_area {
        margin: 20px 20px 0px 20px;
      }
    }
  }
  .person_Card {
    width: 690px;
    height: 200px;
    margin-left: 30px;
    border-radius: 12px;
    margin-bottom: 50px;
    overflow: hidden;
    img {
      margin-right: 10px;
      width: 48%;
      height: 100%;
    }
  }
}
</style>
